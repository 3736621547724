import React from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from "react-h5-audio-player";

class Audiobooks extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="pricing">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Luisterboeken</h1>
                    {/*<img src={"images/marion/dedicon-logo.svg"} className={"dedicon-logo"} />*/}
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle font-secondary text-muted text-center padding-t-30">Dedicon maakt tekst en beeld leesbaar, zichtbaar, hoorbaar of voelbaar voor mensen met een visuele beperking. Ik help daarbij door boeken, kranten, tijdschriften en studiemateriaal voor te lezen. Inmiddels heb ik 16 boeken voorgelezen voor de stichting Dedicon en Radio Aalsmeer.</p>
                </div>
            </div>


            <div className="row margin-t-50">
                <div className="col-lg-6">
                    <div className="text-center book-box hover-effect">
                        <h4 className="text-uppercase">Angelique Haak</h4>
                        <h1>Zwarte Ziel</h1>
                        <img src={"images/marion/zwartezielhd.jpg"} className={"img-fluid book-picture"} />

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/leesfragment-zwarte-ziel.mp3"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="text-center book-box hover-effect">
                        <h4 className="text-uppercase">Jacques Vriens</h4>
                        <h1>Baron 1898</h1>
                        <img src={"images/marion/baron1898hd.jpg"} className={"img-fluid book-picture"} />

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/leesfragment-baron-1898.mp3"/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="text-center book-box hover-effect">
                        <h4 className="text-uppercase">Mirjam Mous</h4>
                        <h1>Lange Vingers</h1>
                        <img src={"images/marion/langevingershd.jpg"} className={"img-fluid book-picture"} />

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/leesfragment-lange-vingers.mp3"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="text-center book-box hover-effect">
                        <h4 className="text-uppercase">Marcel van Driel</h4>
                        <h1>Onmogelijk Spel</h1>
                        <img src={"images/marion/onmogelijkspelhd.jpg"} className={"img-fluid book-picture"} />

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/leesfragment-onmogelijk-spel.mp3"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Audiobooks;
