import React from 'react';

class Services extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Voor een stem met vertrouwen, kracht en overtuiging</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">Voor jouw merk, product of dienst wil je een ijzersterke stem om jouw verhaal zo goed mogelijk over te brengen. Dat doe ik.</p>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-headphones text-custom" />
                            <h4 className="padding-t-15">Commercials</h4>
                            <p className="padding-t-15 text-muted">Om klanten te overtuigen is een heldere, krachtige stem nodig. Ik heb die stem.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-notebook text-custom" />
                            <h4 className="padding-t-15">Luisterboeken</h4>
                            <p className="padding-t-15 text-muted">Luister bijvoorbeeld naar een uitzending 'Verhalenderwijs' bij <a href="https://radioaalsmeer.nl/programma/verhalenderwijs/uitzending/2021-03-10/" target="_blank">Radio Aalsmeer</a>.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-album text-custom" />
                            <h4 className="padding-t-15">Podcasts</h4>
                            <p className="padding-t-15 text-muted">Ik heb mijn eigen podcast op Spotify genaamd de <a href="https://open.spotify.com/show/6UIAeEjHc4BCfNfk6Ub1jV?si=WQjYWIRaRFmet7FrudsZqA" target="_blank">Millennialscoach Podcast</a>!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Services;
