import React from 'react';
import emailjs from 'emailjs-com';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailResult: ""
    }
  }

  sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_7plqkuh', 'template_gjl012s', e.target, 'user_I9GlcpYK3zF6AoP1CTime')
    .then((result) => {
      this.setState({
        emailResult: "Uw bericht is succesvol verzonden!"
      });
    }, (error) => {
      this.setState({
        emailResult: "Er is iets fout gegaan bij het verzenden van uw bericht."
      });
    });
  }

  render() {
  	return (
        <section className="section" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Neem contact op</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">Als u dit contactformulier invult, kunt u binnen bliksemsnel van mij een antwoord terug verwachten.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="custom-form mt-4 pt-4">
                        <div id="message"></div>
                        <form method="post" onSubmit={(e) => this.sendEmail(e)} name="contact-form" id="contact-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="from_name" id="name" type="text" className="form-control" placeholder="Voor- en achternaam*" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="from_email" id="email" type="email" className="form-control" placeholder="E-mailadres*" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <input name="subject" type="text" className="form-control" id="subject" placeholder="Onderwerp van het bericht.." />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <textarea name="message" id="message" rows="4" className="form-control" placeholder="Uw bericht..." />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" value="Bericht versturen" />
                                    <div id="simple-msg">{this.state.emailResult}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Contact;
