import React from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';

class Features extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="features">
        <div className="container">
            <div className="row vertical-content">
                <div className="col-lg-5">
                    <div className="features-box">
                        <h3>Ik zorg voor een stem met daadkracht en overtuiging. Niets meer en niets minder.</h3>
                        <p className="text-muted web-desc">In de volgende fragmenten is te horen hoe ik mijn stem gebruikt om het publiek te grijpen, vast te houden en weer los te laten.</p>
                        <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                        </ul>

                        <h3 style={{marginTop: "55px"}}>Demo 1</h3>
                        <AudioPlayer
                          className={""}
                          src="audio/marion-demo-1.mp3"/>

                        <h3 style={{marginTop: "50px"}}>Demo 2</h3>
                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/marion-demo-2.mp3"/>

                        <a style={{marginTop: "60px"}} href="#contact" className="btn btn-custom waves-effect waves-light">Neem contact op <i className="mdi mdi-arrow-right" /></a>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="features-img features-right text-right profile-picture-container">
                        <img src="images/marion/marion.jpg" className="img-fluid profile-picture" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Features;
