import React from 'react';
import { Link } from 'react-router-dom';
import AudioPlayer from "react-h5-audio-player";

class Process extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id={"process"}>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <img src={"images/marion/radio-aalsmeer-zwart.svg"} className={"img-fluid radio-aalsmeer"} />
                    <h1 className="section-title text-center">Radio Aalsmeer</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">Voor Radio Aalsmeer ben ik gevraagd om nieuwsberichten in te spreken over alle gebeurtenissen in dit pitoreske dorpje.</p>
                </div>
            </div>
            <div className="row margin-t-50">
                <div className="col-lg-6">
                    <div className="text-center news-box hover-effect">
                        <i className="pe-7s-news-paper text-custom news-icon" />
                        <h1 className={"news-title"}>Nieuwsbericht 1</h1>

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/radio-aalsmeer-nieuwsbericht-1.mp3"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="text-center news-box hover-effect">
                        <i className="pe-7s-news-paper text-custom news-icon" />
                        <h1 className={"news-title"}>Nieuwsbericht 2</h1>

                        <AudioPlayer
                          className={"margin-t-10"}
                          src="audio/radio-aalsmeer-nieuwsbericht-2.mp3"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Process;
